<template>
  <v-banner
    v-show="$auth.user() && show"
    v-model="dismiss"
    transition="slide-y-transition"
    class="please-update-info d-print-none"
    :color="$vuetify.theme.dark ? 'orange darken-4' : 'orange lighten-1'"
    tile app sticky
  >
    <v-icon slot="icon">mdi-progress-alert</v-icon>{{
      $t('onboarding.banner.updatePersonalData')
    }}<template #actions>
      <v-btn text tile :to="{ name: 'EditPersonal' }" @click="dismiss" v-text="$t('onboarding.banner.view')"/>
    </template>
  </v-banner>
</template>

<script>
import Vue from 'vue'
import {intervalToDuration} from "date-fns"

export default Vue.extend({
  name: "PersonalDataUpdateBanner",
  data: () => ({ dismiss: true }),
  computed: {
    show() {
      if (['Onboarding', 'ResetPasswordConfirm', 'Unsubscribe'].includes(this.$route.name)) {
        return false
      }
      let monthsBetweenDates = intervalToDuration({
        start: new Date(this.$store.state.account.last_data_update), end: new Date()},
      )
      monthsBetweenDates = monthsBetweenDates.months + monthsBetweenDates.years * 12
      return this.$auth.user().is_exposed ? monthsBetweenDates >= 6 : monthsBetweenDates >= 12
        && this.$store.state.account.type === 'private'
    },
  },
})
</script>

<style>
.please-update-info .v-banner__wrapper {
  border-bottom: 0 !important;
}

.please-update-info .v-banner__actions {
  margin-bottom: 2px;
}
</style>
