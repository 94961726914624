import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import i18n from './i18n'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params).toString(),
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#1d1f24',
        heading: '#f6f0e9',
        background: '#ffffff',
      },
      dark: {
        primary: '#ffffff',
        heading: '#1e1e1e',
        background: '#000000',
      },
    },
  },
})
