<template>
  <v-text-field
    v-model="number"
    filled
    required
    :rules="rules"
    :label="label"
    class="phonepicker"
  >
    <template #prepend>
      <v-select
        v-model="code"
        filled
        prefix="+"
        :items="countries"
        item-text="phone"
        item-value="phone"
        :rules="[(v) => !!v]"
        required
        class="code"
      >
        <template #item="{item}">
          <BaseCountry :country="item.country" no-tooltip/>
          <span class="pl-2">{{ item.name }} (+{{ item.phone }})</span>
        </template>
      </v-select>
    </template>
  </v-text-field>
</template>
<script>
import PhonePrefixes from "../refs/PhonePrefixes"

import Vue from 'vue'

export default Vue.extend({
  name: "BasePhonepicker",
  props: {
    value:   { type: String, default:  null },
    country: { type: String, default:  null },
    label:   { type: String, required: true },
  },
  data() {
    return {
      code: "",
      number: "",
      rules: [
        v => !!v || this.$t('errors.fieldRequired'),
        v => v && v.length <= 20 || this.$tc('errors.mustBeLessThanXCharacters', 20),
        v => new RegExp('^\\d+$').test(v) || this.$t('errors.incorrectPhoneNumber'),
      ],
    }
  },
  computed: {
    countries() {
      return Object.entries(PhonePrefixes).map(([country, phone]) => {
        return { country, phone, name: this.$t(`countries.${country}`) }
      })
    },
    combined() {
      return `${this.code}${this.number}`
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          this.code = this.countries.find(c => value.startsWith(c.phone))?.phone || ''
          this.number = value.slice(this.code.length)
        } else if (this.country) {
          // OK, empty value provided, just prefill the country code in an attempt to be helpful
          this.code = this.countries.find(c => c.country === this.country)?.phone || ''
        }
      },
    },
    number(value) {
      if (this.code === '') {
        this.number = String(value).replace(/\D/g, "")
      }
    },
    combined(value) {
      this.$emit('input', value)
    },
  },
})
</script>
<style lang="scss">
.phonepicker {
  .code{
    max-width: 7rem;
  }
  .v-input {
    &__prepend-outer{
      margin: 0 9px 0 0 !important;
    }
  }
}
</style>
