import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    account: { id: null },
    liveReloadingHack: false,

    // Snackbar:
    messages: [],
    messageVisible: false,
  },
  mutations: {
    setCurrentInvestmentAccount(state, accountData) {
      state.account = accountData
    },

    setLiveReloadingHack(state, value) {
      state.liveReloadingHack = value
    },

    // Snackbar:
    pushMessage(state, message) {
      state.messages.push(message)
      if (!state.messageVisible) { state.messageVisible = true }
    },
    performMessageShift(state) {
      if (state.messageVisible) { return }
      state.messages.shift()
      if (state.messages.length > 0) { state.messageVisible = true }
    },
    shiftMessage(state) {
      state.messageVisible = false
      setTimeout(() => {
        this.commit('performMessageShift')
      }, 350)
    },
  },
  modules: {},
  strict: false, // TODO turn off for production
})
