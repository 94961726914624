import Vue from 'vue'
import VueI18n, { DateTimeFormats, FallbackLocale } from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  for (const key of locales.keys()) {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  }
  return messages
}

const dateTimeFormats: DateTimeFormats = {
  'en': {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
}

export default new VueI18n({
  locale: localStorage.getItem('locale') || 'en',
  messages: loadLocaleMessages(),
  dateTimeFormats: dateTimeFormats,
  // In bypass locale we show full keys, for other locales we show just the last part. This way,
  // dynamically generated strings show up as something readable before a translation is added.
  fallbackLocale: {
    'de': [ 'en' ],
    'et': [ 'en' ],
    'ru': [ 'en' ],
    'bypass': [ 'bypass' ],
  } as unknown as FallbackLocale,
  missing: (locale, key, vm, values) => {
    if (locale !== 'bypass') {
      // So translation wasn't found, and we need to return something for user to see.
      const isBackendValidationError = key.includes(' ')
      if (isBackendValidationError) {
        // e.g. "serverMessages.Ensure this value is less than or equal to 14999.99"
        // Simply splitting by '.' would mess up the float number, so let's carefully cut out
        // everything before the first dot, and keep only the error message.
        return key.substring(key.indexOf('.') + 1)
      } else {
        // e.g. "serverMessages.maximum_amount_per_investment"
        return key.split('.').pop()
      }
    }
    let readableText // XXX translated string will be appended for e2e test backcompat (remove later?)
    try {
      if (vm.$te(key, 'en')) {
        readableText = vm.$t(key, 'en', values)
      }
    } catch {
      // OK, we were not able to convert it, that's absolutely fine
    }
    return readableText ? `｢${key}｣ ${readableText}` : key
  },
  silentFallbackWarn: true,
})
