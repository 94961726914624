<template>
  <v-banner
    v-model="dismiss"
    transition="slide-y-transition"
    class="waiting-for-funds d-print-none"
    :color="$vuetify.theme.dark ? 'orange darken-4' : 'orange lighten-1'"
    tile app sticky
  >
    <v-icon slot="icon">mdi-progress-alert</v-icon>{{
      onbordingStatusText
    }}<template #actions>
      <v-btn text tile :to="{ name: 'Onboarding', params: { back: true } }" @click="dismiss" v-text="$t('onboarding.banner.finishOnboardingButton')"/>
    </template>
  </v-banner>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "OnboardingBanner",
  data: () => ({ dismiss: true }),
  computed: {
    onbordingStatusText() {
      return this.$store.state.account.is_confirmed_assignment &&
        !this.$store.state.account.is_approved &&
        !!this.$store.state.account.payment_cards.length ?
        this.$t('onboarding.banner.finishVerificationPaymentText') :
        this.$t('onboarding.banner.finishOnboardingText')
    },
  },
})
</script>

<style>
.waiting-for-funds .v-banner__wrapper {
  border-bottom: 0 !important;
}

.waiting-for-funds .v-banner__actions {
  margin-bottom: 2px;
}
</style>
