import _Vue from "vue"
import { VUE_APP_WS_URL } from "../config"

import { EventBus } from '@/wsupdate/eventbus'

export default {
  install(Vue:typeof _Vue, options = {}):void {
    Vue.prototype.$reconnectDelay = options['reconnectDelay'] || 1000
    Vue.prototype.$openSocket = () => {
      const token = Vue.prototype.$auth.token()
      // If we ain't got the token, we're logged out/kicked out
      // Stop attempting to reconnect
      if (!token) {
        Vue.prototype.$updateSocket = null
        return
      }

      if (!Vue.prototype.$updateSocket) {
        const updateSocket = new WebSocket(`${options['url'] || VUE_APP_WS_URL}/updates/?token=${token}`)
        Vue.prototype.$updateSocket = updateSocket
        updateSocket.onmessage = (e) => {
          const data = JSON.parse(e.data)
          EventBus.$emit(`update-${data.what}`)
        }
        updateSocket.onclose = (e) => {
          if (e.code != 1000) {
            console.error('Updates socket closed unexpectedly!')
            console.error(e)
            // Attempt to reconnect
            if (!options['disableReconnect']) {
              setTimeout(() => {
                Vue.prototype.$openSocket()
              }, Vue.prototype.$reconnectDelay)
              Vue.prototype.$reconnectDelay = Math.min(
                Vue.prototype.$reconnectDelay * options['delayMultiplier'] || 2,
                options['maxDelay'] || 60000,
              )
            }
          }
          Vue.prototype.$updateSocket = null
        }
      }
    }

    Vue.prototype.$closeSocket = () => {
      if (Vue.prototype.$updateSocket) {
        // close and dispose
        Vue.prototype.$updateSocket.close()
        Vue.prototype.$updateSocket = null
      }
    }
  },
}
