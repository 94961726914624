var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',_vm._b({attrs:{"persistent-hint":"","items":_vm.sortedItems,"menu-props":{ maxHeight: '400' },"clearable":"","multiple":_vm.multiple,"label":( // Display 'All' when none is selected and no hint is provided
    !_vm.isIndeterminate
    && !_vm.isEverythingSelected
    && !_vm.$attrs.filled
    && _vm.$attrs.hint) ? _vm.$t('autoInvest.all') : _vm.$attrs.label},on:{"change":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([(_vm.items.length > 2 && _vm.multiple)?{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.toggleSelection,"mousedown":function($event){$event.preventDefault();}}},[_c('v-list-item-action',[_c('v-simple-checkbox',{attrs:{"value":_vm.isEverythingSelected,"indeterminate":_vm.isIndeterminate,"ripple":false,"color":"primary"},on:{"click":_vm.toggleSelection,"mousedown":function($event){$event.preventDefault();}}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t('common.selectAll'))}})],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}:null,(_vm.multiSelectDataType == 'country')?{key:"selection",fn:function(ref){
    var item = ref.item;
return [_c('span',[_c('BaseCountry',{attrs:{"country":item.value,"no-tooltip":""}}),_c('span',{staticClass:"pl-1 pr-2 pt-1",domProps:{"textContent":_vm._s(_vm.$t(("countries." + (item.text))))}})],1)]}}:null,{key:"item",fn:function(ref){
    var item = ref.item;
    var attrs = ref.attrs;
    var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({class:{'allSelected': _vm.isEverythingSelected && _vm.items.length > 2},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var active = ref.active;
return [(_vm.multiple)?_c('v-list-item-action',[_c('v-simple-checkbox',_vm._g({attrs:{"value":active,"ripple":false}},on))],1):_vm._e(),_c('v-list-item-content',[(_vm.multiSelectDataType == 'country')?_c('v-list-item-title',[_c('BaseCountry',{attrs:{"country":item.value,"no-tooltip":""}}),_c('span',{staticClass:"pl-4",domProps:{"textContent":_vm._s(_vm.$t(("countries." + (item.text))))}})],1):(_vm.multiSelectDataType == 'loanType')?_c('v-list-item-title',[_c('span',{staticClass:"pl-0",domProps:{"textContent":_vm._s(item.name)}})]):_c('v-list-item-title',[_c('span',{domProps:{"textContent":_vm._s(item)}})])],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}],null,true)},'v-autocomplete',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }