import { Vue } from './app'
import * as Sentry from "@sentry/vue"
import { Integrations } from "@sentry/tracing"

import App from "./App.vue"
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from "./router"
import store from "./store"
import i18n from "./i18n"
import wsupdate from "./wsupdate"
import VueMq from "vue-mq"
import VueExcelXlsx from "vue-excel-xlsx"
import VueCookies from 'vue-cookies'


import auth from '@websanova/vue-auth/dist/v2/vue-auth.esm.js'
import driverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js'
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js'

import './base/components'
import './modules/common/components'
import { StripePlugin } from '@vue-stripe/vue-stripe'

import vuetify from './vuetify'
import { VUE_APP_ENVIRONMENT, VUE_APP_API_URL, VUE_APP_SENTRY_DSN, VUE_APP_STRIPE_PUBLISHABLE_KEY, VUE_APP_WS_URL } from "./config"

Sentry.init({
  Vue,
  dsn: VUE_APP_SENTRY_DSN,
  environment: VUE_APP_ENVIRONMENT,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

const stripeOptions = {
  pk: VUE_APP_STRIPE_PUBLISHABLE_KEY,
}

if (VUE_APP_STRIPE_PUBLISHABLE_KEY) {
  Vue.use(StripePlugin, stripeOptions)
}

// XXX move the code below to separate files. Also do that in the Backoffice
Vue.use(VueExcelXlsx)
Vue.use(VueCookies)
Vue.use(VueAxios, axios)
axios.defaults.baseURL = VUE_APP_API_URL

Vue.use(auth, {
  plugins: {
    http: Vue.axios,
    router: router,
  },
  drivers: {
    auth: {
      request: function(req, token) {
        this.drivers.http.setHeaders.call(this, req, {
          Authorization: 'Bearer ' + token,
        })
      },
      response: function(res) {
        const body = this.drivers.http.httpData(res)
        // TODO handle refresh tokens here
        return body.access
      },
    },

    http: driverHttpAxios,
    router: driverRouterVueRouter,
  },
  options: {
    rolesKey: 'type',
    authRedirect: { path: '/login' },
    loginData: { url: 'auth/jwt/create/' },
    logoutData: { redirect: '/login' },
    registerData: { url: 'auth/users/', redirect: false },
    refreshData: { enabled: false },
    fetchData: { url: 'auth/users/me/' },
    parseUserData: function(data) {
      if (data) {
        if (!store.state.account.id) {
          const selected_account = data.investment_accounts.find(acc => acc.id === data.last_selected_investment_account)
          store.commit('setCurrentInvestmentAccount', selected_account || data.investment_accounts[0])
        } else {
          const account = data.investment_accounts.find(acc => acc.id === store.state.account.id)
          store.commit('setCurrentInvestmentAccount', account)
        }
      }
      return data || {}
    },
    notFoundRedirect: { name: 'error-404' },
  },
})

axios.interceptors.response.use(
  function(response) {
    return response
  },
  function(error) {
    if (error.response) { // request was sent and response was received
      if (error.response.status === 401) { // unauthorized (expired token or no longer has access)
        Vue.auth.logout({})
        store.commit("setCurrentInvestmentAccount", {})
      }
      const Some400Errors = [
        'maxAttemptsExceeded', 'codeNotFound', 'codeExpired', 'wrongCodeNewIsSent',
        'alreadyRegistered', 'hasNotBeenSent', 'alreadySent', 'limitExceeded',
      ]
      const Some401Errors = [
        'Given token not valid for any token type',
        'Authentication credentials were not provided.',
      ]
      if (error.response.status === 400 && Some400Errors.includes(error.response.data.code)) {
        // do nothing
      } else if (error.response.status === 401 && Some401Errors.includes(error.response.data.detail)) {
        // do nothing
      } else if (typeof error.response.data === 'object') { // json result
        /* eslint-disable @typescript-eslint/no-explicit-any*/
        let text = ``
        try {
          const translated = (id) => i18n.t(`serverMessages.${id.replace(/\.$/, '')}`)
          for (const key of Object.entries(error.response.data.payment_method || error.response.data)) {
            if (typeof key[1] == 'string') {
              text += `\n${translated(key[0])}: ${translated(key[1])}`
            } else {  // array instead of string
              if ((<any>key[1]).length > 0 && typeof key[1][0] === 'string') {  // handle array of strings
                text += `\n${translated(key[0])}: ${(<any>key[1]).map(el => translated(el)).join(', ')}`
              } else {  // handle array of dicts
                for (const message of key[1] as Array<any>) {  // key[1] is array
                  for (const m of Object.entries(message)) {  // message is dict
                    text += `\n${translated(m[0])}: ${translated(m[1])}`
                  }
                }
              }
            }
          }
        } catch (errorToBeIgnored) {
          store.commit('pushMessage', {
            icon: 'mdi-robot-confused',
            text: `${i18n.t('serverErrors.cannotMakeThatRequest')}\n\n${JSON.stringify(error.response.data)}`,
          })
          return Promise.reject(error)
        }
        /* eslint-enable @typescript-eslint/no-explicit-any*/
        store.commit('pushMessage', {
          icon: 'mdi-robot-angry',
          text: i18n.t('serverErrors.cannotMakeThatRequest') + text,
        })
      } else {
        store.commit('pushMessage', {
          icon: 'mdi-robot-confused',
          text: i18n.t('serverErrors.requestFailed'),
        })
      }
    } else if (error.request) { // response not received but request was sent
      store.commit('pushMessage', {
        icon: 'mdi-robot-dead',
        text: i18n.t('serverErrors.couldNotReceiveResponse'),
      })
    } else { // something else
      if (error.code != "ERR_CANCELED") { // Don't error out if we canceled our request
        store.commit('pushMessage', {
          icon: 'mdi-alert',
          text: i18n.t('serverErrors.somethingWentWrong'),
        })
      }
    }
    return Promise.reject(error)
  },
)

Vue.use(VueMq, {
  breakpoints: {
    mobile: 1200,
    desktop: Infinity,
  },
})

Vue.use(wsupdate, {
  url: VUE_APP_WS_URL,
  disableReconnect: false,
  reconnectDelay: 1000,
  delayMultiplier: 2,
  maxDelay: 60000,
})

export const app = new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
