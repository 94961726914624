<template>
  <v-banner
    v-show="isVisisble"
    transition="slide-y-transition"
    class="d-print-none"
    :color="$vuetify.theme.dark ? 'orange darken-4' : 'orange lighten-1'"
    tile app sticky
  >
    <v-icon slot="icon">mdi-alert</v-icon>{{ $t('infoMessages.wrongAccountBanner.text') }}
    <template #actions>
      <v-btn text tile @click="logout" v-text="$t('infoMessages.wrongAccountBanner.logoutButton')"/>
    </template>
  </v-banner>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "WrongAccountBanner",
  computed: {
    isVisisble() {
      return this.$auth.user() && !this.$auth.user()?.investment_accounts?.length
    },
  },
  methods: {
    logout() {
      this.$auth.logout()
      if (!this.$store.state.account) { // it is supposed to not exist for LO
        this.$router.go("/")
      }
      this.$store.commit("setCurrentInvestmentAccount", {})
    },
  },
})
</script>
