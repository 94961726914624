import Vue from 'vue'
import BaseCountry     from "./BaseCountry"
import BaseBoolean     from "./BaseBoolean"
import BaseArray       from "./BaseArray"
import BaseObject      from "./BaseObject"
import BaseDate        from "./BaseDate"
import BaseSnackbar    from "./BaseSnackbar"
import BasePercent     from "./BasePercent"
import Datepicker      from "./Datepicker"
import BasePhonepicker from "./BasePhonepicker"
import BaseValue       from "./BaseValue"
import BaseMultiselect from "./BaseMultiselect"
import BaseSubtitle    from "./BaseSubtitle"

Vue.component('BaseCountry',     BaseCountry)
Vue.component('BaseBoolean',     BaseBoolean)
Vue.component('BaseArray',       BaseArray)
Vue.component('BaseObject',      BaseObject)
Vue.component('BaseDate',        BaseDate)
Vue.component('BaseSnackbar',    BaseSnackbar)
Vue.component('BasePercent',     BasePercent)
Vue.component('Datepicker',      Datepicker)
Vue.component('BasePhonepicker', BasePhonepicker)
Vue.component('BaseValue',       BaseValue)
Vue.component('BaseMultiselect', BaseMultiselect)
Vue.component('BaseSubtitle',    BaseSubtitle)
