<template>
  <v-banner
    v-if="documentToAccept"
    :value="show"
    transition="slide-y-transition"
    class="privacy-policy d-print-none"
    :color="$vuetify.theme.dark ? 'cyan darken-4' : 'cyan lighten-4'"
    tile app sticky
  >
    <v-icon slot="icon">mdi-file-document-multiple-outline</v-icon>{{
      $t('agreements.acceptDocument', {documentName: documentToAccept.name })
    }}<template #actions>
      <v-btn text tile @click="updateDocumentNag = false">
        {{ $t('agreements.dismiss') }}
      </v-btn>
      <v-btn text tile :to="{ name: 'Settings', hash: '#agreements' }" @click="updateDocumentNag = false">
        {{ $t('agreements.viewAgreements') }}
      </v-btn>
    </template>
  </v-banner>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "NewAgreementBanner",
  data() {
    return {
      updateDocumentNag: false,
      items: [],
    }
  },
  computed: {
    show() {
      return this.updateDocumentNag && this.user && this.$route.name && this.$route.name != 'Onboarding' && this.$route.name != 'ResetPasswordConfirm'
    },
    user() {
      return this.$auth.user()
    },
    documentToAccept() {
      return this.items.find(agreement => !agreement.consent
                             && agreement.category != 'direct_marketing')
    },
  },
  watch: {
    'user.id': function() {
      if (this.user) { this.fetch() }
    },
    documentToAccept(value) {
      if (value) {
        this.updateDocumentNag = true
      }
    },
  },
  methods: {
    fetch() {
      this.axios
        .get(`/agreements/`)
        .then((response) => {
          this.items = response.data.results
        })
    },
  },
})
</script>
<style>
.privacy-policy .v-banner__wrapper {
  border-bottom: 0 !important;
}

.privacy-policy .v-banner__actions {
  margin-bottom: 2px;
}
</style>
