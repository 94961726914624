<template>
  <v-banner
    v-show="$auth.user() && show"
    transition="slide-y-transition"
    class="please-update-info d-print-none"
    :color="$vuetify.theme.dark ? 'orange darken-4' : 'orange lighten-1'"
    tile app sticky
  >
    <v-icon slot="icon">mdi-progress-alert</v-icon>
    {{ $t('onboarding.banner.pleaseReverifyYourself') }}
    <template #actions>
      <v-btn text tile @click="dialog = true" v-text="$t('onboarding.banner.Reverify')"/>
    </template>
    <v-dialog v-model="dialog" max-width="33rem">
      <v-card>
        <v-card-title v-text="$t('onboarding.banner.reverification')"/>
        <v-card-text class="pt-4" v-text="$t('onboarding.banner.youAreAboutToRestart')"/>
        <v-card-actions class="justify-end">
          <v-btn text @click="dialog = false" v-text="$t('startInvesting.cancel')"/>
          <v-btn
            color="green"
            text
            :loading="loading"
            @click="reverify"
            v-text="$t('onboarding.banner.reverify')"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-banner>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import { intervalToDuration } from 'date-fns'

export default Vue.extend({
  name: "ReverifyBanner",
  data: () => ({
    dialog: false,
    loading: false,
  }),
  computed: {
    show() {
      if (['Onboarding', 'ResetPasswordConfirm', 'Unsubscribe'].includes(this.$route.name)) {
        return false
      }
      if (this.$auth.user().veriff_decision_created_at === null) {
        return false
      }
      if (!this.$auth.user().is_exposed) {
        return false
      }
      let monthsBetweenDates = intervalToDuration({
        start: new Date(this.$auth.user().veriff_decision_created_at), end: new Date()},
      )
      monthsBetweenDates = monthsBetweenDates.months + monthsBetweenDates.years * 12
      return monthsBetweenDates >= 5
    },
  },
  methods: {
    reverify() {
      this.loading = true
      axios.post('/auth/users/reverify/', {
      })
        .then(() => this.$auth.fetch())
        .then(() => {
          this.loading = false
          this.dialog = false
          this.$router.push('/register')
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
})
</script>

<style scoped>
.please-update-info .v-banner__wrapper {
  border-bottom: 0 !important;
}

.please-update-info .v-banner__actions {
  margin-bottom: 2px;
}
</style>
