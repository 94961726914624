<template>
  <v-banner
    v-show="$auth.user() && show"
    transition="slide-y-transition"
    class="please-update-info d-print-none"
    :color="$vuetify.theme.dark ? 'orange darken-4' : 'orange lighten-1'"
    tile app sticky
  >
    <v-icon slot="icon">mdi-progress-alert</v-icon>
    Card deposits are temporarily unavailable, please use bank transfer instead.
    <template #actions>
      <v-btn text plain tile @click="banner = false"><v-icon>mdi-close</v-icon></v-btn>
    </template>
  </v-banner>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "CardDepositUnavailableBanner",
  data: () => ({ banner: true }),
  computed: {
    show() {
      return false
      // if (['Onboarding', 'ResetPasswordConfirm', 'Unsubscribe'].includes(this.$route.name)) {
      //   return false
      // }
      // if (this.banner === false) {
      //   return false
      // }
      // return true
    },
  },
})
</script>

<style scoped>
.please-update-info {
  margin-bottom: 2px;
}
</style>
