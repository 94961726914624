<template>
  <v-menu v-model="open" :close-on-content-click="false" transition="scale-transition">
    <template #activator="{ on }">
      <v-text-field
        v-model="dateFormatted"
        filled
        :label="label"
        v-bind="$attrs"
        placeholder="dd.MM.yyyy"
        @blur="update"
      >
        <template #append>
          <v-btn v-bind="$attrs" icon text right v-on="on" @click="open = true">
            <v-icon>
              mdi-calendar
            </v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </template>

    <v-card>
      <v-date-picker :value="date" :max="max" :min="min" :locale="$i18n.locale" no-title :first-day-of-week="1" @input="pickerUpdate"/>
    </v-card>
  </v-menu>
</template>
<script>
import Vue from 'vue'
import { format } from 'date-fns'

export default Vue.extend({
  name: "Datepicker",
  inheritAttrs: false,
  props: {
    value: { type: [String, Date], default:  '' },
    min: { type: String, default:  null },
    max: { type: String, default:  null },
    label: { type: String, required: true },
  },
  data() {
    return {
      dateFormatted: null,
      open: false,
    }
  },
  computed: {
    date() {
      if (this.value instanceof Date) {
        const value = format(this.value, "yyyy-MM-dd")
        this.$emit('input', value) // Let parrent component to refresh v-model
        return value
      }
      return this.value
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.dateFormatted = this.formatDate(this.date)
        this.open = false
      },
    },
    dateFormatted() {
      // this.update() // TODO we probably want this?
    },
  },
  methods: {
    formatDate(date) {
      if (!/\d{4}-\d{1,2}-\d{1,2}/g.test(date)) {
        return date
      }
      const [year, month, day] = new String(date).split("-")
      return `${day.padStart(2, "0")}.${month.padStart(2, "0")}.${year}`
    },
    parseDate(date) {
      if (!/\d{1,2}\.\d{1,2}\.\d{4}/g.test(date)) {
        return null
      }
      const [day, month, year] = date.split(".")
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
    },
    pickerUpdate(value) {
      this.$emit('input', value)
    },
    update() {
      if (this.open) {
        return
      }
      const value = this.parseDate(this.dateFormatted)
      this.$emit('input', value)
    },
  },
})
</script>
