import Vue from 'vue'

Vue.component('Container',            () => import('./Container'))
Vue.component('Footer',               () => import('./Footer'))
Vue.component('ProgressBar',          () => import('./ProgressBar'))
Vue.component('BaseMoney',            () => import('./BaseMoney'))
Vue.component('BaseLocale',           () => import('./BaseLocale'))
Vue.component('BaseNavigationDrawer', () => import('./BaseNavigationDrawer'))
Vue.component('BaseLoanOriginatorChip', () => import('./BaseLoanOriginatorChip'))
Vue.component('BaseAutoInvestStrategyChip', () => import('./BaseAutoInvestStrategyChip'))
