import { VueAuth } from "@websanova/vue-auth"
import Vue from 'vue'
import { AxiosResponse } from "axios"


declare module "vue/types/vue" {
    interface Vue {
        $auth: VueAuth;
    }
    interface VueConstructor {
        auth: VueAuth;
    }
}

export { Vue, AxiosResponse }
