<template>
  <v-banner
    v-show="$auth.user() && $auth.user().birthdate && !$auth.user().is_18_years_old && $route.name === 'Onboarding'"
    transition="slide-y-transition"
    class="d-print-none"
    :color="$vuetify.theme.dark ? 'orange darken-4' : 'orange lighten-1'"
    tile app sticky
  >
    <v-icon slot="icon">mdi-progress-alert</v-icon>{{
      $t('onboarding.banner.ageRestrictionText')
    }}
  </v-banner>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "AgeRestrictionBanner",
})
</script>
