<template>
  <v-banner
    v-show="$auth.user() && show"
    transition="slide-y-transition"
    class="please-update-info d-print-none"
    :color="$vuetify.theme.dark ? 'orange darken-4' : 'orange lighten-1'"
    tile app sticky
  >
    <v-icon slot="icon">mdi-progress-alert</v-icon>
    <i18n path="onboarding.banner.pleaseReverifyYourselfWithANewDocument">
      <template #date>
        <BaseDate v-if="$auth.user() && show" :date="$auth.user().doc_date"/>
      </template>
    </i18n>
    <template #actions>
      <v-btn text tile @click="dialog = true" v-text="$t('onboarding.banner.Reverify')"/>
    </template>
    <v-dialog v-model="dialog" max-width="33rem">
      <v-card>
        <v-card-title v-text="$t('onboarding.banner.reverification')"/>
        <v-card-text class="pt-4" v-text="$t('onboarding.banner.youAreAboutToRestart')"/>
        <v-card-actions class="justify-end">
          <v-btn text @click="dialog = false" v-text="$t('startInvesting.cancel')"/>
          <v-btn
            color="green"
            text
            :loading="loading"
            @click="reverify"
            v-text="$t('onboarding.banner.reverify')"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-banner>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'

export default Vue.extend({
  name: "DocumentExpirationBanner",
  data: () => ({
    dialog: false,
    loading: false,
  }),
  computed: {
    show() {
      if (['Onboarding', 'ResetPasswordConfirm', 'Unsubscribe'].includes(this.$route.name)) {
        return false
      }
      if (!this.$auth.user()) {
        return false
      }
      if (!this.$auth.user().doc_date) {
        return false
      }
      const docDate = new Date(this.$auth.user().doc_date)
      docDate.setMonth(docDate.getMonth() - 1)
      const today = new Date()
      return docDate < today && this.$auth.user().verification === 'approved'
    },
  },
  methods: {
    reverify() {
      this.loading = true
      axios.post('/auth/users/reverify/', {
      })
        .then(() => this.$auth.fetch())
        .then(() => {
          this.loading = false
          this.dialog = false
          this.$router.push('/register')
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
})
</script>

<style scoped>
.please-update-info .v-banner__wrapper {
  border-bottom: 0 !important;
}

.please-update-info .v-banner__actions {
  margin-bottom: 2px;
}
</style>
