export default {
  "AT":  "43",
  "BE":  "32",
  "BG": "359",
  "CH":  "41",
  "CY": "357",
  "CZ": "420",
  "DE":  "49",
  "DK":  "45",
  "EE": "372",
  "ES":  "34",
  "FI": "358",
  "FR":  "33",
  "GR":  "30",
  "HR": "385",
  "HU":  "36",
  "IE": "353",
  "IS": "354",
  "IT":  "39",
  "LI": "423",
  "LT": "370",
  "LU": "352",
  "LV": "371",
  "MT": "356",
  "NL":  "31",
  "NO":  "47",
  "PL":  "48",
  "PT": "351",
  "RO":  "40",
  "SE":  "46",
  "SI": "386",
  "SK": "421",
}
